import { apiSlice } from "./";

// Constants
const QUESTION_LIST = "/list";
const DOWNLOAD_LIST = "/download-assessment";
const UPLOAD_URL = "/upload-response";
const REGISTER_USER = "/register-user";
const SIGN_IN = "/sign-in";
const FEEDBACK = "/register-feedback";
const DOWNLOAD_REPORT = "/get-results";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query({
      query: ({language, board}) => ({
        url: `${QUESTION_LIST}/${language}/${board}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    downloadFile: builder.query({
      query: (payload) => ({
        url: `${DOWNLOAD_LIST}/${payload.language}/${payload.board}/${payload.classID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    // upload Assessment json
    addData: builder.mutation({
      query: (payload) => ({
        url: `${UPLOAD_URL}/${payload.schoolName}/${payload.board}/${payload.assessment_id}/Section_${payload.section}/${payload.student_id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: payload,
      }),
    }),
    registerUser: builder.mutation({
      query: (userData) => ({
        url: REGISTER_USER,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: userData,
      }),
    }),
    signIn: builder.mutation({
      query: (credentials) => ({
        url: SIGN_IN,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: credentials,
      }),
    }),
    registerFeedback: builder.mutation({
      query: (feedbackData) => ({
        url: FEEDBACK,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: feedbackData,
      }),
    }),
      query: (classID) => ({
        url: `${DOWNLOAD_REPORT}/${classID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
  }),
});

export const {
  useGetListQuery,
  useDownloadFileQuery,
  useAddDataMutation,
  useRegisterUserMutation,
  useSignInMutation,
  useRegisterFeedbackMutation,
} = userApiSlice;
