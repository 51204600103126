import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ReviewModal from "../../components/AssesmentComponents/modal/ReviewModal";
import ConfettiExplosion from 'react-confetti-explosion';
import { useRegisterFeedbackMutation } from "../../apiSlices/question_submission_ApiSlice";
import { useSelector } from "react-redux";
import ShowToast from "../../components/Toast";
import { DownloadReportButton } from "../../components/report-gen/DownloadReportButton";

const ReviewPage = () => {
  // states for rating
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  // state for modal
  const [showModal, setShowModal] = useState(true);
  // state to control confetti
  const [runConfetti, setRunConfetti] = useState(true);
  const [loading, setLoading] = useState(true);

  const [registerFeedback, { isLoading: isFeedbackLoading, isSuccess: isFeedbackSuccess, isError: isFeedbackError, feedbackError }] =
    useRegisterFeedbackMutation();

  const [review, setReview] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const userDetails = useSelector((state) => state.authReducer.userDetails);

  const handleSubmit = (e) => {
    e.preventDefault();
    try{
      registerFeedback({ email: userDetails?.email, rating: rating, feedback: review }).unwrap();
      ShowToast({
        type: "success",
        message: "Review Submitted Successfully",
        position: "top-right",
        autoClose: 5000,
      });
      setIsSubmitted(true);
    } catch (err) {
      ShowToast({
        type: "error",
        message: err?.data?.message || "Something went wrong.",
        position: "top-right",
        autoClose: 5000,
      });
    }
    
  }

  return (
    <>
      {runConfetti && 
        <div style={{
          position: 'absolute',
          left: "50%",
          right: "50%",
          top: 0,
          height: "110vh"
        }}>
          <ConfettiExplosion 
            force={0.8}
            duration={5000}
            particleCount={200}
            floorHeight={window.innerHeight}
            floorWidth={window.innerwidth}
          />
        </div>
      }
      <div className="review_container">
        <div className="review_heading">
          <h1>Thank you for submitting the assesment.</h1>
          <h2 style={{ textAlign: "center" }}>Leave a Review for Assessli</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="review_rating">
            <p>How would you rate your exam?</p>

            {[...Array(5)].map((star, index) => {
              const currentRating = index + 1;

              return (
                <label key={index}>
                  <input
                    type="radio"
                    name="rating"
                    value={currentRating}
                    onChange={() => setRating(currentRating)}
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="star"
                    color={
                      currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                    }
                    onMouseEnter={() => setHover(currentRating)}
                    onMouseLeave={() => setHover(null)}
                  />
                </label>
              );
            })}

            {/* test the rating */}
            <p style={{ paddingTop: "10px", fontSize: "16px" }}>
              Given rating : {rating}{" "}
            </p>
          </div>
          <textarea name="review" id="review" placeholder="Write a review..." value={review} onChange={handleReviewChange}/>
          <div style={{ display: "flex", flexDirection: 'column',justifyContent: "space-between", alignItems: "center", width: '100%', gap: '8px'}}>
            <button 
              type="submit" 
              style={{ 
                fontSize: 16,
                backgroundColor: isSubmitted ? '#4a0fb3' : '#5e16da', // Darker shade when disabled
                cursor: isSubmitted ? 'not-allowed' : 'pointer' // Change cursor when disabled
              }}
              onClick={handleSubmit}
              disabled={isSubmitted}
            >
              {isSubmitted ? 'Review Submitted' : 'Submit Review'}
            </button>
            {/* <DownloadReportButton /> */}
            <p>
              All reviews are verified within 48 hours before
              posting to ensure authenticity and accuracy.
            </p>
          </div>
        </form>
        {/* {showModal && (
          <ReviewModal
            title="Thank you for submitting your review!"
            text="All reviews on XYZ are verified within 48 hours before posting to ensure authenticity and accuracy."
            onClose={() => setShowModal(false)}
          />
        )} */}
      </div>
    </>
  );
};

export default ReviewPage;
